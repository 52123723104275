import React, { useEffect, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const shapes = [
  "aspect-square h-3/4 rounded-full",
  "aspect-square h-3/4 rounded-lg",
  "h-3/5 w-3/4 rounded-lg",
  "aspect-[1/2] h-3/4 rounded-3xl",
];

const SlideshowAuto = ({ slides }) => {
  const [counter, setCounter] = useState(1);
  const handleNext = () => {
    if (counter !== slides.length) {
      setCounter(counter + 1);
    } else {
      setCounter(1);
    }
  };

  const loopShapes = () => {
    var arr = [];
    for (var i = 0; i < slides.length; i++) {
      arr.push(shapes[0], shapes[1], shapes[2], shapes[3]);
    }
    return arr.flat();
  };

  useEffect(() => {
    let interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval);
  });

  return (
    <div className="">
      <div className="relative">
        {slides.map((slide, index) => {
          const slideImag = getImage(
            slide.localFile.childImageSharp.gatsbyImageData
          );

          return (
            <div
              className={
                counter - 1 === index
                  ? "absolute justify-center items-center animate-pulse"
                  : "hidden"
              }
              key={index}
            >
              <div className="text-white absolute z-30 w-full h-full flex justify-center items-center">
                <div
                  className={`${
                    loopShapes()[counter - 1]
                  }  border-2 border-white`}
                ></div>
              </div>
              {slide && (
                <GatsbyImage
                  image={slideImag}
                  alt={slide.caption}
                  imgStyle=""
                  backgroundColor="black"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SlideshowAuto;
