import * as React from "react";

export function Diagram({ classes }) {
  return (
    <svg
      id="c"
      data-name="signs"
      xmlns="http://www.w3.org/2000/svg"
      // width="861.96"
      // height="622.76"
      viewBox="0 0 861.96 622.76"
      className={``}
    >
      <g>
        <path
          d="M459.72,370.19l-29.22,50.61c-1.93,3.35,.48,7.54,4.35,7.54h58.44c3.87,0,6.29-4.19,4.35-7.54l-29.22-50.61c-1.93-3.35-6.77-3.35-8.7,0Z"
          className="stroke-[2px] stroke-black fill-none"
          style={{ fill: "none" }}
        />
        <line
          x1="464.07"
          y1="428.34"
          x2="464.07"
          y2="439.56"
          className="stroke-[2px] stroke-black fill-none"
        />
      </g>
      <line
        x1="1.35"
        y1="274.64"
        x2="858.29"
        y2="4.33"
        className="stroke-[2px] stroke-black fill-none"
      />
      <g>
        <text transform="translate(480.22 218.66)" className="text-md">
          <tspan x="0" y="0">
            Justice
          </tspan>
        </text>
        <text transform="translate(419.57 218.66)" className="text-md">
          <tspan x="0" y="0">
            ...
          </tspan>
        </text>
      </g>
      <g>
        <text transform="translate(389.23 320.97)" className="text-md">
          <tspan x="0" y="0">
            Health
          </tspan>
        </text>
        <g>
          <text transform="translate(166.16 320.97)" className="text-md">
            <tspan x="0" y="0">
              Economy
            </tspan>
          </text>
          <text transform="translate(98.7 320.97)" className="text-md">
            <tspan x="0" y="0">
              ...
            </tspan>
          </text>
        </g>
        <g>
          <text transform="translate(549.3 320.97)" className="text-md">
            <tspan x="0" y="0">
              Environment
            </tspan>
          </text>
          <text transform="translate(773.92 320.97)" className="text-md">
            <tspan x="0" y="0">
              ...
            </tspan>
          </text>
        </g>
      </g>
      <g>
        <text transform="translate(563 423.27)" className="text-md">
          <tspan x="0" y="0">
            Policy
          </tspan>
        </text>
        <text transform="translate(683.41 425.32)" className="text-md">
          <tspan x="0" y="0">
            ...
          </tspan>
        </text>
      </g>
      <line
        x1="858.29"
        y1="4.33"
        x2="858.29"
        y2="615.92"
        style={{ strokeDasharray: "0 0 3 10" }}
        className="stroke-[2px] stroke-black fill-none"
      />
      <g>
        <line
          x1="689.7"
          y1="221.76"
          x2="689.7"
          y2="232.98"
          className="stroke-[2px] stroke-black fill-none"
        />
        <g>
          <rect
            x="644.02"
            y="139.64"
            width="91.36"
            height="72.89"
            rx="5.87"
            ry="5.87"
            transform="translate(865.78 -513.62) rotate(90)"
            className="stroke-[2px] stroke-black"
            style={{ fill: "none" }}
          />
        </g>
      </g>
      <g>
        <line
          x1="350.09"
          y1="282.09"
          x2="350.09"
          y2="293.3"
          className="stroke-[2px] stroke-black fill-none"
        />
        <g>
          <rect
            x="327.82"
            y="229.66"
            width="44.56"
            height="44.56"
            rx="3.21"
            ry="3.21"
            transform="translate(775.79 182.53) rotate(135)"
            className="stroke-[2px] stroke-black fill-none"
            style={{ fill: "none" }}
          />
        </g>
      </g>
      <g>
        <line
          x1="773.92"
          y1="502.03"
          x2="773.92"
          y2="513.25"
          className="stroke-[2px] stroke-black fill-none"
        />
        <g>
          <circle
            cx="773.92"
            cy="470.8"
            r="31.24"
            className="stroke-[2px] stroke-black fill-none"
            style={{ fill: "none" }}
          />
        </g>
      </g>
      <line
        x1="1.35"
        y1="348.13"
        x2="858.29"
        y2="618.43"
        className="stroke-[2px] stroke-black fill-none"
      />
    </svg>
  );
}
