import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArrowRight, EyeOutline } from "../images/icons";
import Layout from "../components/layout";
import DropDownButton from "../components/dropDownMenu/dropDownButton";
import { GetCategories } from "../utils/categoriesFilter";
import { GetLocations } from "../utils/locationsFilter";
import SignsGridClean from "../components/signGrid/signsGridClean";
import SlideShowAuto from "../components/slideShowAuto";
import { undelineMD, undelineSM } from "../utils/cssGroups";
import { slugify } from "../utils/slugify";
import { Seo } from "../components/seo";
import More from "../components/accordion/more";
const IndexPage = (props) => {
  // ---> Get all Signs <--- //
  const signs = props.data.allStrapiSign.nodes;
  const events = props.data.allStrapiEvent.nodes;
  const introBlurb = props.data.strapiIntroBlurb.IntroBlurb.data.IntroBlurb;
  const introSubtitle = props.data.strapiIntroBlurb.introSubtitle;
  const introOpen = props.data.strapiIntroBlurb.introOpen;

  const signYearRange = signs.map((sign) => {
    return Number(sign.year);
  });

  // ------> Filtered data <------ //
  const [signFilteredData, setSignFilteredData] = React.useState(signs);
  // ------> Unique category selected <------ //
  const [selectedSignCategory, setSelectedSignCategory] = React.useState(
    GetCategories(signs) && GetCategories(signs)[0]
  );
  // ------> Unique location selected <------ //
  const [selectedSignLocation, setSelectedSignLocation] = React.useState(
    GetLocations(signs) && GetLocations(signs)[0]
  );
  // Selected Menu top
  const [selected, setSelected] = React.useState(null);

  // ------> Pin Scroll Position <------ //
  // Helper function that allows finding first element in the view port
  const findFirstElementInViewPort = (elements) =>
    Array.prototype.find.call(
      elements,
      (element) => element.getBoundingClientRect().y >= 85 // nav height offset
    );
  // Ref to the container with elements
  const containerRef = React.useRef(null);
  const scrollTo = React.useMemo(() => {
    // Find all elements in container which will be checked if are in view or not
    const nodeElements = containerRef.current?.querySelectorAll("[data-item]");
    if (nodeElements) {
      return findFirstElementInViewPort(nodeElements);
    }
    return undefined;
  }, []);
  // }, [signFilteredData]);

  React.useLayoutEffect(() => {
    if (scrollTo) {
      // Scroll to element with should be in view after rendering
      scrollTo.scrollIntoView();
      // Scroll by height of nav
      window.scrollBy(0, -85);
    }
  }, [scrollTo, signFilteredData]);

  // getCategoriesFromAll signs and make them one array
  const allEventCategories = events.map((a) =>
    a.eventSigns.map((a) => a.categories.map((a) => a.category))
  );
  let uniqueEventCategories = new Set(allEventCategories.flat().flat());
  let eventCategories = Array.from(uniqueEventCategories);
  eventCategories = [...new Set(eventCategories.flat())];

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <div className=" text-white ">
        {events.map((event, index) => {
          // images:
          const eventFeatSign = getImage(
            event.eventFeaturedSign.localFile.childImageSharp.gatsbyImageData
          );
          // images
          // const eventFeatSign = getImage(
          //   event.eventFeaturedSign.localFile.childImageSharp.gatsbyImageData
          // );
          // const eventFeatMap = getImage(
          //   event.eventFeaturedMap.localFile.childImageSharp.gatsbyImageData
          // );
          // Date Format
          const date = new Date(event.eventDate);
          const dateFormated = date
            .toDateString()
            .split(" ")
            .slice(1)
            .join(" ");

          return event.eventFeatured ? (
            <div
              key={index}
              className="text-black  bg-amber-300 p-5  mb-[1.3em]"
            >
              {/* Slogen  */}
              <div className="text-md md:text-lg  w-full lg:w-3/4  text-black  capitalize">
                {Math.min(...signYearRange)}-{Math.max(...signYearRange)}:{" "}
                {introSubtitle}
              </div>
              {/* More Accordion */}
              <More content={introBlurb} open={introOpen} />

              {/* Recent Event */}
              <div className="capitalize text-md md:text-lg mb-[0.65em]">
                Recent {event.eventType}:
              </div>
              {/* ---> Carrusel  */}
              <div className="flex justify-between w-full">
                {/* Slide - auto*/}
                <div className="basis-1/2 relative">
                  <SlideShowAuto slides={event.eventImages} />
                </div>
                {/* Sign */}
                <div className="basis-1/2 flex justify-center items-center bg-black">
                  {event.eventFeaturedSign && (
                    <GatsbyImage
                      image={
                        eventFeatSign
                        // event.eventFeaturedSign.localFile.childImageSharp
                        //   .gatsbyImageData
                      }
                      alt={event.eventFeaturedSign.caption}
                      className=""
                      imgStyle=""
                    />
                  )}
                  {/* {console.log(event.eventFeaturedSign)} */}
                </div>
              </div>

              {/* Block of details */}
              <div className="flex justify-between items-start flex-wrap mt-[0.65em]">
                {/* ---> Title <--- */}
                <Link
                  to={`/events/${event.slug}`}
                  className={`group text-black text-md md:text-lg mb-[1.3em] capitalize ${undelineMD}`}
                >
                  {event.eventTitle}
                  <br />
                  <span className="capitalize">
                    ({event.eventLocation.country}: {dateFormated})
                  </span>
                </Link>
              </div>

              {/* ---> Mapping categories of all signs <--- */}
              <div className="relative">
                <div className="">
                  <EyeOutline classes={"h-5 md:h-6"} />
                  <div className="my-[1.3rem]">Issues explored:</div>
                  <div className="flex flex-wrap content-start">
                    {eventCategories.map((eventCategory, index) => {
                      return (
                        <div
                          key={index}
                          className="mr-5 inline-flex capitalize"
                        >
                          <Link
                            to={`/categories/${slugify(eventCategory)}`}
                            className={`${undelineSM}`}
                          >
                            {eventCategory}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        })}

        <div className="text-white mb-[1.3em] text-md md:text-lg ">
          <div className="text-amber-300">Explore the Signs:</div>
          <div className="flex items-center">
            <div className="mr-5">2022</div>
            <ArrowRight classes={"h-5 pt-1 mr-5"} />
            <div className="">2085</div>
          </div>
        </div>
        <div className="sticky top-[2.6em] lg:top-[3.9em] z-30">
          <div className="flex">
            <DropDownButton
              menuItems={GetCategories(signs)}
              signs={signs}
              setSignFilteredData={setSignFilteredData}
              setSelectedSignCategory={setSelectedSignCategory}
              menuTitle={"category"}
              selectedSignCategory={selectedSignCategory}
              selectedSignLocation={selectedSignLocation}
              selected={selected}
              setSelected={setSelected}
            />
            <DropDownButton
              menuItems={GetLocations(signs)}
              signs={signs}
              setSignFilteredData={setSignFilteredData}
              setSelectedSignLocation={setSelectedSignLocation}
              menuTitle={"locations"}
              selectedSignCategory={selectedSignCategory}
              selectedSignLocation={selectedSignLocation}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
        {/* All signs here */}
        <div ref={containerRef} className={""}>
          <SignsGridClean signFilteredData={signFilteredData} />
        </div>
      </div>
    </Layout>
  );
};
export const query = graphql`
  query IndexQuery {
    strapiIntroBlurb {
      IntroBlurb {
        data {
          IntroBlurb
        }
      }
      introSubtitle
      introOpen
    }
    allStrapiSign {
      nodes {
        slug
        title
        year
        locationContext {
          city
          country
        }
        categories {
          category
        }
        heroImage {
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: NO_CHANGE
                #width: 700
                height: 400
                quality: 100
                #layout: FULL_WIDTH
                transformOptions: { fit: COVER }
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allStrapiCategory {
      group(field: category) {
        edges {
          node {
            category
          }
        }
      }
    }
    allStrapiEvent {
      nodes {
        addReactions {
          participantImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          participantName
          participantQuote
        }
        eventFeaturedMap {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                #backgroundColor: f8f8f8
                aspectRatio: 1.9
                formats: NO_CHANGE
                quality: 100
                transformOptions: {
                  fit: CONTAIN
                  cropFocus: ATTENTION
                  duotone: { highlight: "#fcd34d", shadow: "#000000" }
                }
                layout: CONSTRAINED
              )
            }
          }
        }
        eventFeaturedSign {
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.5
                placeholder: DOMINANT_COLOR
                formats: NO_CHANGE
                quality: 100
                transformOptions: {
                  fit: CONTAIN
                  cropFocus: ATTENTION
                  #duotone: { highlight: "#fcd34d", shadow: "#000000" }
                }
                layout: CONSTRAINED
              )
            }
          }
        }
        eventSigns {
          categories {
            category
          }
        }
        eventType
        eventImages {
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                formats: NO_CHANGE
                aspectRatio: 1.5
                # height: 200
                quality: 100
                transformOptions: {
                  fit: COVER
                  cropFocus: ATTENTION
                  duotone: { highlight: "#fcd34d", shadow: "#000000" }
                }
                layout: CONSTRAINED
              )
            }
          }
        }
        eventLocation {
          country
        }
        eventDate
        eventFeatured
        eventTitle
        eventType
        slug
      }
    }
  }
`;

export default IndexPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Home"}
    description={
      "Specualtive design global project Exploring The Impact Of Extreme Climate Futures Through Signage Systems"
    }
    keywords={"specualtive design, art instalation"}
  />
);
