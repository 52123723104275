import * as React from "react";
import { Diagram } from "../../images/diagram";
import { getWindowSize } from "../../utils/getWindowSize";
import ReactMarkdown from "react-markdown";

const More = ({ content, open }) => {
  const [clicked, setClicked] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const contentEl = React.useRef();

  const [windowSize, setWindowSize] = React.useState(
    getWindowSize().innerWidth
  );

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize().innerWidth, setHeight("min-content"));
    }
    typeof window !== "undefined" &&
      window.addEventListener("resize", handleWindowResize);
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  const handleToggle = () => {
    setClicked(!clicked);
  };
  React.useEffect(() => {
    clicked && setHeight(contentEl.current.scrollHeight);
  }, [clicked]);

  //   -----
  return (
    <div
      className={` ${
        clicked ? "active" : ""
      } mb-[2.6em] mt-[0.325em] md:mt-[0.65em]`}
    >
      <button className=" text-base" onClick={handleToggle}>
        <div className="">{clicked ? "..." : "Read more..."}</div>
      </button>
      <div
        ref={contentEl}
        className={` overflow-hidden transition-all ease-out duration-300`}
        style={clicked ? { height: height } : { height: "0px" }}
      >
        <div
          className={`text-black py-0 text-base md:text-md ${
            clicked ? "opacity-100" : "opacity-0"
          } transition-opacity duration-500`}
        >
          {/* Open */}
          <div className="mt-[1.3rem] mb-[2.6em] text-md md:text-lg">
            {" "}
            {open}
          </div>

          {/* Diagram */}
          {/* Dates */}
          <div className="grid grid-cols-4 grid-flow-row mb-[1.3rem] text-sm md:text-base">
            <div className=""></div>
            <div className="col-span-2">
              <div className="flex justify-between text-sm md:text-base lg:text-md">
                <div>2023</div>
                <div>2057</div>
              </div>
            </div>
            <div className=""></div>
          </div>
          {/* Diagram */}
          <div className="h-fit grid grid-cols-4 grid-flow-row items-center text-sm md:text-base">
            <div className=" text-black justify-self-end mr-5 md:mr-10 text-sm md:text-base lg:text-md">
              My Present
            </div>
            <div className="col-span-2">
              <Diagram />
            </div>
            <div className=" text-black ml-5 md:ml-10 text-sm md:text-base lg:text-md">
              My Futures
            </div>
          </div>
          {/* Caption */}
          <div className="grid grid-cols-4 grid-flow-row mb-[0] md:mb-[1.3rem] mt-[1.3rem] text-sm md:text-base">
            <div className=""></div>
            <div className="col-span-2">
              <div className="flex justify-between text-sm md:text-base lg:text-md ">
                <div className="text-sm md:text-sm lg:text-base ">
                  Manifesting 'everyday' future policies through signage
                </div>
              </div>
            </div>
            <div className=""></div>
          </div>

          {/* Content */}
          <div className="mt-[2.6em] mb-[1.3em]  columns-1 lg:columns-2">
            <ReactMarkdown
              components={{
                p: ({ node, ...props }) => (
                  <p className="mb-[1.3em] last:mb-[0]" {...props} />
                ),
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default More;
